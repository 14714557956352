import Layout from '../components/layout';
import Anchor from '../components/typography/anchor';
import BotImage from '../components/botimage';
import pridebot from 'images/bots/pridebot.svg';

const NotFoundPage = () => {
  return (
    <Layout title="Resistbot - Page Not Found">
      <section className="text-center">
        <BotImage src={pridebot} />
        <h1 className="mt-4">I can’t find that, resister!</h1>

        <p>
          Try{' '}
          <Anchor className="anchor-text" href="/petitions">
            trending petitions
          </Anchor>
          , the{' '}
          <Anchor className="anchor-text" href="/guide">
            keyword guide
          </Anchor>
          ,{' '}
          <Anchor className="anchor-text" href="/about">
            help page
          </Anchor>
          , or the{' '}
          <Anchor className="anchor-text" href="/">
            home page
          </Anchor>{' '}
          to get started.
        </p>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
